<template>
  <v-container fill-height>
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      :snackTop="true"
      @close="snackOn = false"
    />

    <v-row
      align="center"
      justify="center"
    >
      <v-col
        xs="12"
        sm="12"
        md="12"
        lg="8"
        xl="6"
      >
        <v-form ref="form">
          <v-card
            outlined
            rounded="xl"
          >
            <v-container>
              <v-row justify="center">
                <v-col cols="8">
                  <v-window v-model="step">
                    <v-window-item :value="1">
                      <CheckIn
                        @signin="step++"
                      />
                    </v-window-item>

                    <v-window-item :value="2">
                      <CheckOut :showCoupon="true" />
                    </v-window-item>
                  </v-window>
                </v-col>

                <v-col cols="4">
                  <v-sheet
                    class="py-2"
                    :color="sheetColor"
                    rounded="xl"
                    height="100%"
                    width="100%"
                  >
                    <v-list-item>
                      <v-list-item-avatar
                        size="70"
                        rounded="xl"
                      >
                        <v-img src="https://hisolverlab.s3.amazonaws.com/images/292108530_598397031910689_3854908716991734244_n.jpg" />
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          Iuvenis
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          Mentoria - 1 mês
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <div :class="textClass">
                        • Acesso à plataforma com aulas técnicas gravadas
                      </div>
                    </v-list-item>

                    <div class="py-1" />

                    <v-list-item>
                      <div :class="textClass">
                        • Plantões de dúvidas semanais ao vivo
                      </div>
                    </v-list-item>

                    <div class="py-1" />

                    <v-list-item>
                      <div :class="textClass">
                        • Acesso a comunidade interna da iuvenis
                      </div>
                    </v-list-item>

                    <div class="py-1" />

                    <v-list-item>
                      <div :class="textClass">
                        • Divulgação de oportunidades, networking, terapia em grupo e muito mais
                      </div>
                    </v-list-item>

                    <div class="py-1" />
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { SnackBar } from '@components/App'
import { nameRegex } from '@utils/regex'
import { mapGetters, mapActions } from 'vuex'

import CheckIn from '@components/Wallet/CheckIn'
import CheckOut from '@components/Wallet/CheckOut'

import API from '@api'

import {
  mdiAccount,
  mdiEmail,
  mdiLock,
  mdiAt
} from '@mdi/js'

const { VUE_APP_SAFE_SIGNUP } = process.env

export default {
  components: {
    SnackBar,
    CheckIn,
    CheckOut
  },

  created() {
    if (this.profileUsername) {
      this.step++
    }
  },

  data() {
    return {
      mdiAccount,
      mdiEmail,
      mdiLock,
      mdiAt,

      step: 1,

      // window 1
      nameRegex,
      loading: false,
      snackMsg: '',
      snackOn: false,
      fullName: '',
      email: '',
      password: '',
      passwordConfirm: '',

      //window 2
      safeSignup: VUE_APP_SAFE_SIGNUP === 'true'
    }
  },

  computed: {
    ...mapGetters({
      profile: 'profile/id',
      profileUsername: 'profile/username',
      user: 'user'
    }),

    textClass() {
      return this.$vuetify.theme.dark ? 'grey--text text--lighten-1' : 'grey--text text--darken-2'
    },

    sheetColor() {
      return this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'grey lighten-5'
    }
  },

  methods: {
    ...mapActions({
      setSession: 'setSession'
    }),

    submit() {
      if (this.safeSignup) {
      
        this.validateAccount()
      
      } else {

        this.submitAccount()

      }
    },

    async submitAccount () {

      this.loading = true

      if (!navigator.cookieEnabled) {

        this.snackMsg = 'noCookies'
        this.snackOn = true

      } else if (this.$refs.form.validate()) {

        const fullName = this.fullName
        const email = this.email
        const password = this.password
        const userData = {
          fullName,
          password,
          email 
        }

        try {

          const {user, profile, authorizationToken} = await API().post('/user', userData)
          
          this.setSession({user, profile, authorizationToken})
          this.$socket.onlineSession.connect()

          this.$router.push({name: 'profile.edit', query: { new: true } })

        } catch(err) {

          this.snackMsg = err.response.data.errMsg
          this.snackOn = true

        } finally {

          this.loading = false

        }

      }
    },

    validateAccount() {
      if (this.$refs.form.validate()) {
        
        this.loading = true

        API().post(`/user/validate/email/${this.$i18n.locale}`, {
          email: this.email.toLowerCase(),
          fullName: this.fullName,
          origin: 'STUDY_URL'
        })
        .then(() => {
          this.loading = false
          this.snackMsg = 'signupEmailSent'
          this.snackOn = true
        })
        .catch(err => {
          const responseData = err.response.data
          this.snackMsg = responseData.errMsg
            ? responseData.errMsg
            : 'signupErr'
          this.snackOn = true
          this.loading = false
        })

      }
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 200% !important;
  font-weight: 300; 
  margin: 0;
}

a {
  text-decoration: none;
}
</style>