<template>
  <v-container>
    <v-row jusitfy="center">
      <v-col cols="12">
        <Scrollbar
          :thin="true"
          autoHide="leave"
          :style="scrollStyle"
        >
          <v-container>
            <v-form ref="form">
              <v-card-text>

                <div :class="`grey--text text--${ $vuetify.theme.dark ? 'lighten-2' : 'darken-3' }`">Dados de cobrança</div>

                <v-divider class="my-2" />

                <div class="my-4" />

                <v-row justify="center">
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="customerName"
                      :rules="[v => v.trim().replace(/ +/g, ' ').split(' ').length > 1 && nameRegex.test(v) || $t('validation.fullName')]"
                      dense
                      outlined
                      label="Seu nome completo"
                      @input="updateCustomer = true"
                    />
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="customerEmail"
                      :rules="[v => /.+@.+\..+/.test(v) || $t('validation.email')]"
                      dense
                      outlined
                      label="Email de cobrança"
                      @input="updateCustomer = true"
                    />
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="customerDocument"
                      dense
                      outlined
                      label="CPF (só números)"
                      :rules="[v => v && numOnlyRegex.test(v) || 'Insira somente números']"
                      @input="updateCustomer = true"
                    />

                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col
                    cols="6"
                    class="py-0"
                  >
                    <v-autocomplete
                      v-model="mobile.countryCode"
                      :items="countryCodesObj"
                      item-text="country_en"
                      item-value="phone_code"
                      label="Código do país do celular"
                      persistent-hint
                      outlined
                      dense
                      @input="updateCustomer = true"
                    ></v-autocomplete>
                  </v-col>

                  <v-col
                    cols="6"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="mobile.areaCode"
                      :rules="[v => v && numOnlyRegex.test(v) || 'Insira somente números']"
                      dense
                      outlined
                      label="Código da área do celular"
                      @input="updateCustomer = true"
                    />
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="mobile.number"
                      :rules="[v => v && numOnlyRegex.test(v) || 'Insira somente números']"
                      dense
                      outlined
                      label="Número do celular"
                      @input="updateCustomer = true"
                    />
                  </v-col>
                </v-row>

                <v-row
                  v-if="showCoupon"
                  justify="center"
                >
                  <v-col
                    cos="12"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="couponCode"
                      dense
                      outlined
                      label="Coupon"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>

            <v-container class="pt-0">

              <div :class="`grey--text text--${ $vuetify.theme.dark ? 'lighten-2' : 'darken-3' }`">Formas de pagamento</div>

              <v-divider class="my-2" />

              <div class="my-2" />

              <div class="overline">
                Lembre de clicar no botão para verificar o pagamento (ou recarregar a página) após realizar o pagamento
              </div>

              <div :class="`text--subtitle-2 grey--text text--${ $vuetify.theme.dark ? 'lighten-2' : 'darken-2' }`">
                Cartão de Crédito
              </div>

              <div class="my-2" />
              
              <v-fade-transition hide-on-leave>
                <v-btn
                  v-if="transactionStatus != 'approved' && !payment_url"
                  color="primary"
                  block
                  :loading="loading"
                  @click="submit"
                >
                  gerar link de pagamento
                </v-btn>
              </v-fade-transition>

              <v-fade-transition hide-on-leave>
                <v-row
                  v-if="transactionStatus != 'approved' && payment_url"
                  justify="center"
                  class="px-6"
                >
                  <v-btn
                    color="primary"
                    :href="payment_url"
                  >
                    acessar link de pagamento
                  </v-btn>

                  <v-spacer />

                  <v-btn
                    text
                    :loading="loading"
                    @click="renewPayment('checkout')"
                  >
                    renovar link
                  </v-btn>
                </v-row>
              </v-fade-transition>

              <v-fade-transition hide-on-leave>
                <v-btn
                  v-if="transactionStatus === 'approved'"
                  block
                  color="primary"
                  @click="contentRoute"
                >
                  acessar conteúdo
                </v-btn>
              </v-fade-transition>

              <div class="my-4" />

              <div :class="`text--subtitle-2 grey--text text--${ $vuetify.theme.dark ? 'lighten-2' : 'darken-2' } mb-2`">
                Pix
              </div>

              <v-fade-transition hide-on-leave>
                <v-btn
                  v-if="transactionStatus != 'approved' && !qrCode"
                  color="primary"
                  block
                  :loading="loading"
                  @click="submit"
                >
                  {{ qrCode ? 'renovar' : 'gerar' }} pix qr code                
                </v-btn>
              </v-fade-transition>

              <v-fade-transition hide-on-leave>
                <v-row
                  v-if="transactionStatus != 'approved' && qrCode"
                  justify="center"
                  class="px-6"
                >
                  <v-btn
                    color="primary"
                    :loading="loading"
                    @click="validatePayment"
                  >
                    validar pagamento               
                  </v-btn>

                  <v-spacer />

                  <v-btn
                    text
                    :loading="loading"
                    @click="renewPayment('pix')"
                  >
                    renovar qrcode               
                  </v-btn>
                </v-row>
              </v-fade-transition>

              <v-fade-transition hide-on-leave>
                <v-btn
                  v-if="transactionStatus === 'approved'"
                  block
                  color="primary"
                  @click="contentRoute"
                >
                  acessar conteúdo
                </v-btn>
              </v-fade-transition>

              <div class="my-4" />

              <v-row
                v-if="qrCode && transactionStatus != 'approved'"
                justify="center"
              >
                <v-card    
                  outlined
                  rounded="lg"
                  max-height="200"
                  max-width="200"
                >
                  <v-img :src="qrCode">
                    <v-skeleton-loader
                      v-if="!qrCode"
                      class="mx-auto"
                      min-height="200"
                      min-width="200"
                      type="image"
                    ></v-skeleton-loader>
                  </v-img>
                </v-card>            
              </v-row>

            </v-container>
  
          </v-container>
        </Scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from '@api'
import { mapGetters } from 'vuex'
import { countryCodes } from '@utils'
import { find } from 'lodash'
import { Scrollbar } from '@components/App'
import { numOnlyRegex, nameRegex } from '@utils/regex';

export default {
  components: {
    Scrollbar
  },

  props: {
    subscription: {
      type: Object,
      default() {
        return {}
      }
    },
    product: {
      type: Object,
      default() {
        return {}
      }
    },
    successUrl: {
      type: String,
      default: ''
    },
    showCoupon: {
      type: Boolean,
      default: true
    }
  },

  async mounted () {

    const [customer] = await API().get('/customers', {
      params: {
        user: this.user.id
      }
    })

    if (customer) {

      this.customerName = customer.name
      this.customerEmail = customer.email
      this.customerDocument = customer.document
      this.mobile = {
        countryCode: Number(customer.mobile.countryCode),
        areaCode: customer.mobile.areaCode,
        number: customer.mobile.number
      }

      this.customer = customer

      if (this.subscription && this.subscription._id) {

        this.subscriptionStatus = this.subscription.status

        this.setSubscriptionTransaction()

      }

    }

  },

  data() {
    return {
      nameRegex,
      numOnlyRegex,

      checkOutTab: 0,
      couponCode: '',

      subscriptionStatus: '', 

      updateCustomer: false,

      customer: {},

      qrCode: '',
      qrCodeObj: {},

      payment_url: '',

      customerName: '',
      customerEmail: '',
      customerDocument: '',

      mobile: {
        countryCode: 55,
        areaCode: '',
        number: ''
      },

      transactionId: '',
      transactionStatus: '',
      transactionObj: {},

      price: 50000
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id'
    }),
  
    installments() {
      return [
        {
          number: 1,
          interestRate: 1.05
        },
        {
          number: 2,
          interestRate: 1.07
        },
        {
          number: 3,
          interestRate: 1.08
        },
        {
          number: 4,
          interestRate: 1.09
        },
        {
          number: 5,
          interestRate: 1.1
        }
      ]
    },

    scrollStyle() {
     return this.$vuetify.breakpoint.xl
      ? 'height: 500px;'
      : 'height: 400px;'
    },

    countryCodesObj() {
      return countryCodes.map(({ country_en, phone_code }) => {
        return {
          phone_code,
          country_en: `${country_en}, ${phone_code}`
        }
      })
    }
  },

  watch: {
    subscription: {
      handler() {
        this.setSubscriptionTransaction()
      },
      deep: true
    }
  },

  methods: {
    contentRoute() {
      window.location.href = this.successUrl
    },

    async setSubscriptionTransaction() {

        const transactions = await API().get('/transactions', {
          params: {
            customer: this.customer._id
          }
        })

        const transaction = find(transactions, transaction => {
          return transaction._id === this.subscription.transaction
        })

        if (transaction) {

          this.transactionObj = transaction
          this.transactionStatus = transaction.status
          this.transactionId = transaction.transactionId

          if (transaction.checkout && transaction.checkout.payment_url) {
          
            this.payment_url = transaction.checkout.payment_url
          
          }

          if (transaction.qrCode && transaction.qrCode.qr_code_url) {

            this.qrCode = transaction.qrCode.qr_code_url
            this.qrCodeObj = transaction.qrCode

          }

          this.validatePayment()
        
        }
    },

    couponCodes(code) {
      switch(code) {
        case 'o0jk0n8dWe5BaTPA':
          return 0.1;
        case 'FPhpsYqL1TAoYl2b':
          return 0.2
        case 'pqsTuJRPJXzZJOcq':
          return 0.3
        case 'kt78bE6d9OytAzW1':
          return 0.4
        case 'SZHd1z2IGbO6VopE':
          return 0.5
        case 'z7Q11wMhG7A8073q':
          return 0.7
        case 'AvLAU0fz63CCMp0f':
          return 0.85
        case 'AvLAU0fz63CCffff':
          return 0.90
        case 'AvLAU08z63CCffff':
          return 0.99
        default:
          return 0
      }
    },

    async getCustomer() {

      if (this.customer.user) {

        if (this.updateCustomer) {

          await API().put('/customer', {
            user: this.user.id,
            name: this.customerName,
            email: this.customerEmail,
            document: this.customerDocument,
            mobile: this.mobile
          }, {
            params: {
              user: this.customer.user
            }
          })

          this.customer.name = this.customerName
          this.customer.email = this.customerEmail
          this.customer.document = this.customerDocument
          this.customer.mobile = this.mobile

        }

        return this.customer

      } else {

        return await API().post('/customer', {
          user: this.user.id,
          name: this.customerName,
          email: this.customerEmail,
          document: this.customerDocument,
          mobile: this.mobile
        })

      }

    },

    async getTransaction(customer, paymentType) {

      if (this.transactionId) {
      
        return this.transactionObj
      
      }

      const discount = this.couponCodes(this.couponCode)
      const installments = this.installments

      const transactionData = {
        paymentType,
        customer,
        items: [{
          amount: Math.floor(this.price * (1 - discount)),
          description: 'Iuvenis Premium',
          quantity: 1,
          code: 123
        }],
        installments: installments.map(({number, interestRate}) => {
          return {
            number,
            total: Math.ceil(this.price * (1 - discount) * interestRate)
          }
        }),
        successUrl: this.successUrl
      }

      if (this.subscription) {
        transactionData['subscription'] = this.subscription._id 
      }

      return await API().post('/transaction', transactionData)

    },

    async renewPayment(paymentType) {
      if (this.$refs.form.validate()) {
        this.loading = true

        const discount = this.couponCodes(this.couponCode)
        const installments = this.installments

        this.transactionObj = await API().get('/transaction/update', {
          params: {
            id: this.transactionObj._id,
            paymentType,
            customer: this.customer,
            items: [{
              amount: Math.floor(this.price * (1 - discount)),
              description: 'Iuvenis Premium',
              quantity: 1,
              code: 123
            }],
            installments: installments.map(({number, interestRate}) => {
              return {
                number,
                total: Math.ceil(this.price * (1 - discount) * interestRate)
              }
            }),
            successUrl: this.successUrl
          }
        })

        if (paymentType === 'checkout') {

          this.payment_url = this.transactionObj.checkout.payment_url
        
        } else {

          this.qrCode = this.transactionObj.qrCode.qr_code_url
          this.qrCodeObj = this.transactionObj.qrCode
        
        }

        this.loading = false
      }
    },

    async submit() {

      this.loading = true

      if (this.$refs.form.validate()) {

        const customerObj = await this.getCustomer()
        const transaction = await this.getTransaction(customerObj, 'checkout')

        this.transactionObj = transaction

        if (!this.subscription || !this.subscription._id) {

          // Subscribes to the product
          const subscription = await API().post('subscription', {
            customer: customerObj._id,
            product: this.product._id,
            transaction: transaction._id,
            status: 'pending_first_payment'
          })

          this.$emit('newSubscription', subscription)

        }

        this.transactionId = transaction.transactionId
        this.payment_url = transaction.checkout.payment_url
        this.qrCode = transaction.qrCode.qr_code_url
        this.qrCodeObj = transaction.qrCode

      }

      this.loading = false

    },

    async validatePayment() {

      const qrPaymentStatus = await API().get('/transaction/status', {
        params: {
          transactionId: this.qrCodeObj.transactionId
        }
      })

      const creditCardPaymentStatus = await API().get('/transaction/status', {
        params: {
          transactionId: this.transactionObj.checkout.transactionId
        }
      })

      if (creditCardPaymentStatus === 'paid' || qrPaymentStatus === 'paid') {

        await API().put('/transaction', {
          status: 'approved'
        },
        {
          params: {
            transactionId: this.transactionId
          }
        })

        if (this.subscription) {
          this.subscription.status = 'active'

          await API().put('subscription', {
            status: 'active',
            transaction: this.transactionObj._id
          },
          {
            params: {
              id: this.subscription._id
            }
          })

          if (this.subscription.community) {

            const member = await API().post(`member/${this.user.id}`, {
              profile: this.profile,
              community: this.subscription.community
            })

            this.$emit('newMember', member)

          }

        }
        
      }

    }

  }

}
</script>