<template>
  <v-container>
    <v-fade-transition hide-on-leave>
      <div v-if="!user">
        <v-tabs
          v-model="checkinTab"
          background-color="transparent"
        >
          <v-tab>Signup</v-tab>
          <v-tab>Signin</v-tab>
        </v-tabs>

        <v-fade-transition hide-on-leave>
          <Signup
            v-if="checkinTab === 0"
            @signupSuccess="doSignin"
          />
        </v-fade-transition>

        <v-fade-transition hide-on-leave>
          <Signin
            v-if="checkinTab === 1"
            @signin="doSignin"
          />
        </v-fade-transition>
      </div>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <div v-if="user && !profileObj.username">
        <ProfileSetup @profileSet="doSignin" />
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import Signup from './Signup.vue'
import Signin from './Signin.vue'
import ProfileSetup from './ProfileSetup.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    Signup,
    Signin,
    ProfileSetup
  },
  
  data() {
    return {
      checkinTab: 0,

      profileObj: {}
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  methods: {
    doSignin(profileObj) {

      if (profileObj.username) {

        this.$emit('signin')
      
      }

    }


  }
}
</script>
