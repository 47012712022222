<template>
  <v-form ref="form">
    <v-progress-linear
      :active="loading"
      indeterminate
      color="primary"
      absolute
      top
    />

    <div class="px-6 pt-6 pb-2">
      <v-text-field
        v-model="fullName"
        :rules="[v => v.trim().replace(/ +/g, ' ').split(' ').length > 1 && nameRegex.test(v) || $t('validation.fullName')]"
        validate-on-blur
        :append-icon="mdiAccount"
        name="fullName"
        :label="$t('fullName')"
        type="text"
        outlined
        dense
      />

      <v-text-field
        v-model="email"
        :rules="[v => /.+@.+\..+/.test(v) || $t('validation.email')]"
        validate-on-blur
        :append-icon="mdiEmail"
        name="email"
        label="e-mail"
        type="text"
        outlined
        dense
        @input="formatEmail"
      />

      <v-text-field
        v-if="!safeSignup"
        v-model="password"
        :append-icon="mdiLock"
        :rules="[v => v.length >= 6 & v.indexOf(' ') === -1 || $t('validation.invalidPwd')]"
        :label="$t('verify.pwdLabel')"
        validate-on-blur
        name="password"
        type="password"
        outlined
        dense
      />

      <v-text-field
        v-if="!safeSignup"
        v-model="passwordConfirm"
        :append-icon="mdiLock"
        :rules="[v => v === password || $t('validation.pwdsDontMatch')]"
        :label="$t('verify.pwdConfirmLabel')"
        validate-on-blur
        name="password"
        type="password"
        outlined
        dense
      />

      <v-card-text>
        <v-checkbox v-model="termsAgreement">
          <template v-slot:label>
            <div v-html="$t('signup.acceptTerms')" />
          </template>
        </v-checkbox>
      </v-card-text>

      <v-card-actions>
        <v-btn
          block
          color="primary"
          :disabled="!termsAgreement"
          @click="submit"
        >
          cadastrar
        </v-btn>
      </v-card-actions>
    </div>
  </v-form>
</template>

<script>
import { SnackBar } from '@components/App'
import { nameRegex } from '@utils/regex'
import { mapActions } from 'vuex'

import API from '@api'

import {
  mdiAccount,
  mdiEmail,
  mdiLock
} from '@mdi/js'

const { VUE_APP_SAFE_SIGNUP } = process.env

export default {
  components: {
    SnackBar
  },

  data () {
    return {
      mdiAccount,
      mdiEmail,
      mdiLock,

      termsAgreement: false,

      nameRegex,
      loading: false,
      snackMsg: '',
      snackOn: false,
      fullName: '',
      email: '',
      password: '',
      passwordConfirm: '',

      safeSignup: VUE_APP_SAFE_SIGNUP === 'true'
    }
  },

  methods: {
    ...mapActions({
      setSession: 'setSession'
    }),

    formatEmail() {
      this.email = this.email.replace(/\s/g, "").toLowerCase()
    },

    submit() {
      if (this.safeSignup) {
      
        this.validateAccount()
      
      } else {

        this.submitAccount()

      }
    },

    async submitAccount () {

      this.loading = true

      if (!navigator.cookieEnabled) {

        this.snackMsg = 'noCookies'
        this.snackOn = true

      } else if (this.$refs.form.validate()) {

        const fullName = this.fullName
        const email = this.email
        const password = this.password
        const userData = {
          fullName,
          password,
          email 
        }

        try {

          const {user, profile, authorizationToken} = await API().post('/user', userData)
          
          this.setSession({user, profile, authorizationToken})
          this.$socket.onlineSession.connect()

          this.$emit('signupSuccess', profile)

        } catch(err) {

          this.snackMsg = err.response.data.errMsg
          this.snackOn = true

        } finally {

          this.loading = false

        }

      }

    },

    validateAccount() {
      if (this.$refs.form.validate()) {

        this.loading = true

        API().post(`/user/validate/email/${this.$i18n.locale}`, {
          email: this.email.toLowerCase(),
          fullName: this.fullName,
          origin: 'STUDY_URL'
        })
        .then(() => {
          this.loading = false
          this.snackMsg = 'signupEmailSent'
          this.snackOn = true
        })
        .catch(err => {
          const responseData = err.response.data
          this.snackMsg = responseData.errMsg ? responseData.errMsg : 'signupErr'
          this.snackOn = true
          this.loading = false
        })
      }
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 200% !important;
  font-weight: 300; 
  margin: 0;
}

a {
  text-decoration: none;
}
</style>