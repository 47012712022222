<template>
  <v-container>

    <input
      ref="avatarInput"
      type="file"
      accept=".png,.jpg,.jpeg,.gif,.svg"
      @change="addAvatar"
    />
    <ImageCropper
      stencilComponent="CircleStencil"
      :imageFile="imageFile"
      :mimeType="mimeType"
      :cropperOn="cropperOn"
      @closeDialog="cropperOn = false"
      @save="saveAvatar"
    />
    
    <v-form ref="form">
      <v-container class="px-6">
        <v-row justify="center">
          <v-hover v-slot:default="{ hover }">
            <v-list-item-avatar
              size="120"
              class="py-0"
              :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'"
              @click="setAvatarInput"
            >
              <v-img :src="avatar">
                <HoverLayer v-if="!avatar || hover">
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-icon
                      dark
                      large
                    >
                      {{ mdiCamera }}
                    </v-icon>
                  </v-row>
                </HoverLayer>
              </v-img>
            </v-list-item-avatar>
          </v-hover>
        </v-row>   
        
        <v-row align="center">
          <v-col
            cols="12"
            class="px-0 py-0"
          >
            <v-list three-line>
              <v-list-item
                two-line
                class="px-3"
              >
                <v-list-item-content>
                  <v-container class="pb-0">
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-text-field
                          v-model="name"
                          dense
                          outlined
                          label="Nome (no seu perfil)"
                          :rules="[rules.name]"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-text-field
                          dense 
                          outlined
                          :rules="[rules.isUniq, rules.charLen, rules.alphanumericOnly]"
                          label="username"
                          validate-on-blur
                          @input="verifyUniq"
                          :prepend-inner-icon="mdiAt"
                          v-model="username"
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          v-model="bio"
                          dense
                          outlined
                          label="Bio"
                        />
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-menu
                          ref="calendarOn"
                          :close-on-content-click="false"
                          :return-value.sync="birthDate"
                          transition="scale-transition"
                          min-width="290px"
                          v-model="calendarOn"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              v-model="birthDate"
                              readonly
                              outlined
                              dense
                              class="clickable"
                              label="Date of birth"
                            />
                          </template>
                          <DatePicker        
                            locale="PT-BR"
                            :calendarOn="calendarOn"
                            @setDate="setBirthDate"
                            @saveDate="saveBirthDate"
                            @cancel="calendarOn = false"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12">
            <v-btn
              block
              color="primary"
              :disabled="loading"
              @click="submit"
            >
              save profile
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { HoverLayer } from '@components'
import { MediaURL } from '@components'
import ImageCropper from '@components/Image/Cropper'
import DatePicker from '@components/DatePicker'
import { mapGetters, mapMutations } from 'vuex'
import API from '@api'

import {
  mdiAccount,
  mdiEmail,
  mdiLock,
  mdiAt,
  mdiCamera,
  mdiClose,
  mdiDelete
} from '@mdi/js'

const usernameReg = new RegExp(/^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/)

export default {

  components: {
    DatePicker,
    ImageCropper,
    HoverLayer
  },

  async created() {

    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  
  },

  data() {
    return {
      mdiAccount,
      mdiEmail,
      mdiLock,
      mdiAt,
      mdiCamera,
      mdiClose,
      mdiDelete,

      loading: false,
      cropperOn: false,

      isUniq: true,

      avatarObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      },
      imageFile: {},
      mimeType: '',
      
      avatar: '',
      username: '',
      bio: '',
      
      brithDate: '',
      calendarOn: false,

      rules: {
        name: v => !!v || 'Qual é o seu nome?',
        isUniq: v => {
          return !!this.isUniq || 'Este username não está disponível'
        },
        charLen: v => !!v && v.length >= 3 && v.length <= 25 || 'Seu username deve ter entre 3 e 25 caracteres',
        alphanumericOnly: v => !!usernameReg.test(v.trim()) || 'O username deve ter caracters alfanuméricos apenas'
      },
    }
  },

  computed: {
    ...mapGetters({
      profile: 'profile/id',
      user: 'user'
    }),
  },

  methods: {
    ...mapMutations({
      setIsValid: 'profile/isValid',
      setAvatar: 'profile/avatar',
      setName: 'profile/name',
      setBio: 'profile/bio'
    }),

    setAvatarInput() {
      this.$refs.avatarInput.click()
    },

    async addAvatar(e) {

      const [imageFile] = e.target.files;
      const mimeType = await this.getMimeType(imageFile);

      if (mimeType === 'image/gif') {

        this.avatarObj.mediaSrc = URL.createObjectURL(imageFile);
        this.avatarObj.filename = imageFile.name
        this.avatarObj.type = 'image/gif'

      } else {
        
        this.mimeType = mimeType;
        this.imageFile = imageFile;
        this.cropperOn = true;

      }

    },

    saveAvatar(imageFile) {

      this.cropperOn = false

      this.avatarObj.mediaSrc = URL.createObjectURL(imageFile)
      this.avatarObj.filename = imageFile.name
      this.avatarObj.type = imageFile.type

      this.avatar = this.avatarObj.mediaSrc

    },

    setBirthDate(date) {
      this.brithDate = date
    },

    saveBirthDate(date) {
      this.$refs.calendarOn.save(date)
    },

    avatarColor(hover) {
      return this.$vuetify.theme.dark
        ? hover
        ? 'grey darken-2'
        : 'grey darken-3'
        : hover
        ? 'grey'
        : 'grey lighten-2'
    },

    async verifyUniq(username) {

      try {

        const { exists } = await API().get('/profile-exists', {
          params: { username }
        })

        this.isUniq = !exists
      
      } catch (err) {

        this.snackMsg = 'errServer'
        this.snackOn = true
        this.snackTop = false

      }

    },

    async submit() {

      if (this.$refs.form.validate()) {

        try {

         this.loading = true

          const profileData = {
            name: this.name,
            bio: this.bio,
            about: this.about,
            username: this.username,
            interests: this.interests,
            birthDate: this.birthDate,
            country: this.country,
            city: this.city,
            isValid: true
          }

          if (this.avatarObj.mediaSrc) {

            const [avatarUrl] = await this.getMediaUrl([this.avatarObj], 'images/avatars')        

            profileData.avatar = avatarUrl
            
            await API().post(`/avatar/${this.user.id}`, {
              profile: this.profile,
              url: avatarUrl
            },
            {
              params: {
                id: this.profile
              }
            })

          }

          const { avatar } = await API().put(`/profile/${this.user.id}`, profileData, {
            params: {
              id: this.profile
            }
          })

          this.setIsValid(true)
          
          if (avatar) {
            this.setAvatar(avatar)
          }
          
          this.setName(this.name)
          this.setBio(this.bio)

          this.$emit('profileSet', {
            username: this.username
          })

        } catch(err) {
        
          this.snackMsg = 'errServer'
          this.snackOn = true
          this.snackTop = false
        
        } finally {

          this.loading = false

        }

      } else {

        this.snackMsg = 'invalidFields'
        this.snackOn = true

      }

    }

  }
}
</script>
