<template>
  <v-container fill-height>
    <v-row 
      align="center" 
      justify="center"
    >
      <v-col cols="12">
        <v-progress-linear
          :active="loading"
          indeterminate
          color="primary"
          absolute
          top
        />

        <v-card-text>
          <v-form 
            @submit.prevent="submit"
            ref="form"
          >
            <v-text-field
              v-model="email"
              :rules="[v => /.+@.+\..+/.test(v) || $t('validation.email')]"
              validate-on-blur
              :append-icon="mdiEmail"
              name="email"
              label="e-mail"
              type="text"
              outlined
              dense
              @input="formatEmail"
            />

            <v-text-field
              v-model="password"
              required
              :append-icon="mdiLock"
              name="password"
              :label="$t('signin.pwd')"
              id="password"
              type="password"
              outlined
              dense
              @input="formatPassword"
            />

            <v-row justify="center" class="px-2">
              <v-btn
                block
                color="primary"
                type="submit"
                :loading="loading"
              >
                {{ $t('signin.enter') }}
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { cleanCookies } from '@utils'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import {
  mdiEmail,
  mdiLock
} from '@mdi/js'
import API from '@api'

export default {
  async created() {
    const noCookies = !navigator.cookieEnabled
    if (noCookies || this.sessionExpired)
      setTimeout(() => {
        this.snackMsg = noCookies ? 'noCookies' : 'sessionExpired'
        this.snackOn = true
      }, 500)
  },

  data() {
    return {
      mdiEmail,
      mdiLock,
      
      snackMsg: '',
      snackOn: false,
      email: '',
      password: '',
      emailPwdReset: '',
      loadingResetPwd: false,
      loading: false,
      dialog: false,

      SIGNIN: true
    }
  },

  computed: {
    ...mapGetters({
      sessionExpired: 'sessionExpired',
      locale: 'locale'
    })
  },

  methods: {
    ...mapMutations({
      setLocale: 'locale'
    }),

    ...mapActions({
      setSession: 'setSession'
    }),

    cleanCookies() {
      cleanCookies()

      this.snackMsg = 'signinCleanCookies'
      this.snackOn = true
    },

    formatPassword() {
      this.password = this.password.replace(/\s/g, "")
    },

    formatEmail() {
      this.email = this.email.replace(/\s/g, "").toLowerCase()
    },

    async submit() {

      if (this.$refs.form.validate()) {
        
        setTimeout(() => {
          
          if (this.SIGNIN && this.loading) {

            this.cleanCookies()

          }

        }, 8000);

        this.loading = true

        try {
          
          const {
            user,
            profile,
            authorizationToken,
            pendingOnBoarding
          } = await API().post('user/login', {
            email: this.email, 
            password: this.password
          })

          this.loading = false
          this.setSession({ user, profile, authorizationToken, pendingOnBoarding })

          this.$socket.onlineSession.connect()

          if (profile.settings) {

            if (this.$vuetify.theme.dark != profile.settings.darkThemeOn) {
              
              this.$vuetify.theme.dark = profile.settings.darkThemeOn

            } if (profile.settings.locale && profile.settings.locale != this.locale) {
            
              this.setLocale(profile.settings.locale)

            }

          }
          this.$emit('signin', profile)

        } catch(err) {

          this.loading = false
          
          const responseData = err.response.data
          this.snackMsg = responseData.errMsg ? responseData.errMsg : 'errServer'
          this.snackOn = true

        }

      }
    },

    resetPwd() {
      if (this.$refs.resetPwd.validate()) {
        this.loadingResetPwd = true
        
        API().post(`user/validate/resetPwdEmail/${this.$i18n.locale}`, {
          email: this.emailPwdReset,
          origin: 'STUDY_URL'
        })
        .then(() => {

          this.loadingResetPwd = false
          this.dialog = false

          this.snackMsg = 'pwdResetEmailSent'
          this.snackOn = true

        })
        .catch(err => {

          this.loadingResetPwd = false

          const responseData = err.response.data

          this.snackMsg = responseData.errMsg ? responseData.errMsg : 'errServer'
          this.snackOn = true

          this.loading = false
          this.dialog = false

        })
      }
    }
  } 
}
</script>

<style scoped>
.title {
  font-size: 200% !important; 
  font-weight: 300; 
  margin: 0;
}
</style>
